import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import LayoutOrigin from "../components/layout-origin"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import AccountFrom from "../components/Forms/AccountForm"
import { IoIosMail } from "react-icons/io";

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const OriginsPage: React.FC<PageProps<DataProps>> = ({ location }) => {

  const [industries, setIndustries] = useState([]);

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia, Plurinational State of",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, Democratic Republic of",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czechia",
    "Cote d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Greenland",
    "Grenada",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonalds Islands",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Reunion",
    "Saint Barthelemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin, (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten, (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Repulic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States Minor Outlying Islands",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, Bolivarian Republic of",
    "Vietnam",
    "Virgin Islands, British",
    "Virgin Islands, U.S",
    "Wallis and Futuna"
  ];

  const data = useStaticQuery(
    graphql`
          query {
            hero_background: file(relativePath: { eq: "origin-hero-bg-img.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            astronaut_img: file(relativePath: { eq: "gatsby-astronaut.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            logo_hero: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            map_img: file(relativePath: { eq: "world map-img.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            early_days_background: file(relativePath: { eq: "origin-bg-img2.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            idea_icon: file(relativePath: { eq: "idea-icon.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            dotted_line: file(relativePath: { eq: "dotted-line.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            peer_club_logo: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            objectives_background: file(relativePath: { eq: "objectives-bg.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            settings_gradient: file(relativePath: { eq: "settings-blue.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            collaboration_gradient: file(relativePath: { eq: "collaboration-blue.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            contract_gradient: file(relativePath: { eq: "contract-blue.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            connection_img: file(relativePath: { eq: "peer-logo-circle.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            laptop_img: file(relativePath: { eq: "peer-club-logo-in-laptop.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            circleMeeting_img: file(relativePath: { eq: "asset-right.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            circleMeetings_img: file(relativePath: { eq: "assets-right.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            point_img: file(relativePath: { eq: "point.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 50
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            join_now_background: file(relativePath: { eq: "join-now-background.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            howWeEvolvedImg: file(relativePath: { eq: "how-we-evolved-img.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            fivePillarsImg: file(relativePath: { eq: "five-pillars-img.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            fivePillarsImgNew: file(relativePath: { eq: "five-pillar.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            peer_logo_new: file(relativePath: { eq: "pharma-peer-new.png" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        `
  )

  //declare image Data
  const hero_background = getImage(data.hero_background)
  const astronaut = getImage(data.astronaut_img)
  const logo_hero = getImage(data.logo_hero)
  const map_img = getImage(data.map_img)
  const early_days_background = getImage(data.early_days_background)
  const idea_icon = getImage(data.idea_icon)
  const dotted_line = getImage(data.dotted_line)
  const peer_club_logo = getImage(data.peer_club_logo)
  const objectives_background = getImage(data.objectives_background)
  const settings_gradient = getImage(data.settings_gradient)
  const collaboration_gradient = getImage(data.collaboration_gradient)
  const contract_gradient = getImage(data.contract_gradient)
  const laptop_img = getImage(data.laptop_img)
  const join_now_background = getImage(data.join_now_background)
  const circlemeeting_img = getImage(data.circleMeeting_img)
  const circlemeetings_img = getImage(data.circleMeetings_img)
  const globalconnections = getImage(data.connection_img)
  const pointhandImg = getImage(data.point_img)
  const howWeEvolvedImg = getImage(data.howWeEvolvedImg)
  const fivePillarsImg = getImage(data.fivePillarsImg)
  const fivePillarsImgNew = getImage(data.fivePillarsImgNew)
  const peer_logo_new = getImage(data.peer_logo_new)

  //for bg image
  const hero_bg = convertToBgImage(hero_background)
  const early_days_bg = convertToBgImage(early_days_background)
  const objectives_bg = convertToBgImage(objectives_background)
  const join_now_bg = convertToBgImage(join_now_background)

  // const brLink =  () => {
  //   var url = "https://www.businessriver.com/";
  //   // window.location.href = url;
  //   window.open(url, '_blank');
  // }


  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await fetch('https://peerclubv2.jenocabrera.online/api/sector');
        const data = await response.json();
        const selectedIndustries = data.data;
        setIndustries(selectedIndustries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchIndustries();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <LayoutOrigin>
        <Seo title="Origins" />

        <section id="origins-hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <Link to="/">
                <GatsbyImage
                  image={peer_logo_new}
                  alt="Logo"
                  className="peer-club-logo"
                />
              </Link>
            </div>
            {/* <div className="col-lg-6">
              <h1>From Ireland to the World</h1>
              <p>The Peer Club originated in Ireland when a number of industry peers decided to stay in touch after attending a business awards ceremony in Dublin to discuss a common problem all their businesses faced. They swapped views and exchanged insights and the challenges were met. They decided to meet whenever they might need one another’s support. News of their activities grew organically and over the years inspired more peer meetings, which spread across different sectors of the economy.</p>
              <p className="mb-0">These collegiate peer meetings are the foundation of The <span>Peer Club</span>.</p>
            </div> */}
            <div className="col-lg-6">
              <h1>Foundation</h1>
              <p>The Peer Club formed organically when a number of industry peers decided to stay in touch after attending a business awards ceremony to discuss a common problem all their businesses faced. They swapped views and exchanged insights and the challenges were met. They decided to meet whenever they might need one another’s support. News of their activities grew organically and over the years inspired more peer meetings, which spread across different sectors of the economy.</p>
              <p className="mb-0">These collegiate peer meetings are the foundation of The <span>Peer Club</span>.</p>
            </div>

            <div className="col-lg-6">
              <GatsbyImage
                image={map_img}
                alt="map"
                className="map-img"
              />
            </div>
          </Container>
        </section>

        <BackgroundImage
          Tag="section"
          className="in-the-early-days-bg"
          {...early_days_bg}
          preserveStackingContext
        >
          <section id="in-the-early-days">
            <Container>
              <div className="col-lg-6 early-days">
                <h2 className="text-blue">In the Early Days</h2>
                {/* <p className="early-days-text">The club was an ad-hoc, volunteer-driven community, facilitated in the main by <a href="https://www.businessriver.com/" target="_blank"><span className="business-river-text">BusinessRiver</span></a>, a Dublin based owner-operator of business communities.</p> */}
                <p className="early-days-text">The club was an ad-hoc, volunteer-driven community, facilitated in the main by <a href="https://www.businessriver.com/" target="_blank">BusinessRiver</a>, an owner-operator of business communities.</p>
                <p className="early-days-text">Under this informal structure, participating peers met and shared their knowledge around common goals and opportunities. During the pandemic, activity moved online and as the participant's objectives were trans-national, the community grew.</p>
                <p className="early-days-text">It became clear that a more formal and managed structure would be required to meet the continued activities of the project. Ultimately BusinessRiver, created The Peer Club, a members only resource that co-ordinates and curates meeting on behalf of its members.</p>
              </div>
              <div className="col-lg-6">
                <GatsbyImage
                  image={circlemeetings_img}
                  alt="circle-of-people"
                  className="people"
                />
              </div>
            </Container>
          </section>

          <section id="objectives">
            <Container>
              <div className="col-lg-6">
                <GatsbyImage
                  image={howWeEvolvedImg}
                  alt="how-we-evolved-img"
                  className="how-we-evolved-img"
                />
              </div>
              <div className="col-lg-6">
                <h2>How We Evolved</h2>
                <p>The sharing of knowledge around common goals and opportunities, remain the focus, but the systems and processes are more defined, robust and scalable.</p>
                <div className="row align-items-center">
                  <div className="how-we-evolved-container">
                    <GatsbyImage
                      image={settings_gradient}
                      alt="settings icon"
                      className="objectives-icon"
                    />
                    <p className="mb-0">The Club operates within the same <Link to="#five-pillars-of-peer-club"> honour system</Link> that guided the very first meetings and which has evolved in step with member needs over the years.</p>
                  </div>
                </div>
                <div className="row align-items-center mt-4">
                  <div className="how-we-evolved-container">
                    <GatsbyImage
                      image={collaboration_gradient}
                      alt="collaboration icon"
                      className="objectives-icon"
                    />
                    <p className="mb-0">Still there are no sales pitches, peers must come to the meetings with a sharing perspective, and mutual respect is at the centre of all exchanges.</p>
                  </div>
                </div>
                <div className="row align-items-center mt-4">
                  <div className="how-we-evolved-container">
                    <GatsbyImage
                      image={contract_gradient}
                      alt="contract icon"
                      className="objectives-icon"
                    />
                    <p className="mb-0">This is why, when a member joins the Club, we ask they sign up to the Code of Conduct.</p>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </BackgroundImage>

        <section id="five-pillars-of-peer-club">
          <Container>
            <div className="col-lg-6">
              <h2 className="five-pillars">Code of Conduct - The 5 Pillars</h2>
              <p>Peers are required to approach all peer to peer meetings with an open mind and in a spirit of collegiality and  knowledge sharing.</p>
              <p>Parties are not allowed to promote any product or service and must focus on the agenda - pre-agreed prior to the meeting - which is discussed and expanded upon.</p>
              <p>The meetings are confidential in nature and the outcomes, unless agreed, remain within the peer group.</p>
              <p>Knowledge Partners, parties with deep domain knowledge, will be welcomed to the meeting and are bound by the same honour system as the peers.</p>
              <p className="mb-0">Peer members will nominate a peer from their number to Chair the meeting and this person is called The Peer Leader who ensures the values of the Club are upheld and the meeting conducted according to these 5 pillars.</p>
            </div>
            <div className="col-lg-6">
              <GatsbyImage
                image={fivePillarsImgNew}
                alt="five-pillars-img"
                className="five-pillars-img"
              />
            </div>
          </Container>
        </section>

        <section class="pb-0" id="join-now">
          <Container>
            <div className="col-lg-6">
              <GatsbyImage
                image={globalconnections}
                alt="connections"
                className="connection-img"
              />
            </div>
            <div className="col-lg-6">
              <div className="join-now-container">
                <div className="join-now-content">
                  <p className="join-now-p">To access the peers in the Club and gain their insights and help, a member issues a note on their areas of expertise, interest and talking points they would like insights on. The Club shares this on their behalf with a group it believes most likely to be aligned with their thoughts.</p>
                  <p className="join-now-p">The Club then manages the relations and connections amongst this group and creates the meeting amongst Peer members who can help.</p>
                  {/* <h2 className="text-black">There Are No Fees For Services To Our Members.</h2>
                  <p className="join-now-text-black">To join click here.</p>
                  <a href="https://app.thepeerclub.com/register?role=Peer" target="_blank"><button className="join-now-btn">JOIN NOW</button></a> */}
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section id="contact">
          <Container>
            <div className="contact-form-container">
              <div className="row">
                <div className="col-lg-4 left-contact-col">
                  {/* <h3 className="contact-form-header">Get in Touch</h3>
                  <p className="text-contact">Any question or remarks? Just write us a message!</p>
                  <div className="email-container">
                    <div>
                      <IoIosMail color={'#fff'} size={22} />
                    </div>
                    <div>
                      <a href="mailto:support@thepeerclub.com" className="contact-form-link">support@thepeerclub.com</a>
                    </div>
                  </div> */}
                  <h3 className="contact-form-header">Request for membership</h3>
                  <p className="text-contact">Please fill-in our form and a membership co-ordinator will be in touch with you within 24 hours.</p>
                  <div className="email-container">
                    <div>
                      <IoIosMail color={'#fff'} size={22} />
                    </div>
                    <div>
                      <a href="mailto:applications@thepeerclub.com" className="contact-form-link">applications@thepeerclub.com</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 contact-right-col">
                  <form action="https://usebasin.com/f/8c66e38cdf83" method="POST" id="contact-us-form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="fname"> First Name <span className="text-red">*</span></label>
                          <input type="text" name="first_name" className="form-control" id="fname" required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="lname"> Last Name <span className="text-red">*</span></label>
                          <input type="text" name="last_name" className="form-control" id="lname" required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="email">Email <span className="text-red">*</span></label>
                          <input type="email" name="email" className="form-control" id="email" required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="pnumber">Phone Number <span className="text-red">*</span></label>
                          <input type="tel" name="phone_number" className="form-control" id="pnumber" required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="country">Country <span className="text-red">*</span></label>
                          <select name='country' className="form-control" id="country" required>
                            <option value="" hidden></option>
                            {countries.map((country, index) => (
                              <option key={country + "-" + index} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="industry">Industry <span className="text-red">*</span></label>
                          <select name="industry" className="form-control" id="industry" required>
                            <>
                              {industries && industries.length > 0 ? (
                                <>
                                  <option value='' hidden></option>
                                  {industries
                                    .filter(({ id }) => id !== 157)
                                    .map(({ name, id }) => (
                                      <React.Fragment key={id}>
                                        <option value={name}>{name}</option>
                                      </React.Fragment>
                                    ))}
                                  {industries.some(({ id }) => id === 157) && (
                                    <React.Fragment key={157}>
                                      <option value={'Other'}>Other</option>
                                    </React.Fragment>
                                  )}
                                </>
                              ) : (
                                <>
                                  <p>No records found.</p>
                                </>
                              )}
                            </>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label for="inquiry">Inquiry <span className="text-red">*</span></label>
                          <textarea name="inquiry" className="form-control" rows="5" id="inquiry" required />
                        </div>
                      </div>
                      <div className="col-lg-12 contact-btn-container">
                        <button type="submit" className="secondary-btn">SUBMIT</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </section>

      </LayoutOrigin>
    </>
  )
}

export default OriginsPage
