import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container-fluid/container-fluid"
import "../scss/homepagestyle.scss"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
// import MapGif from "src/images/map-animated.gif"
import FlipFlapDisplay from "src/components/FLipFlap/FlipFlapEffect"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import handGif from '../images/hand.gif'
import { IoIosMail } from "react-icons/io";

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ location }) => {

  const [industries, setIndustries] = useState([]);
  const [homeModalShow, setHomeModalShow] = useState(false);

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia, Plurinational State of",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, Democratic Republic of",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czechia",
    "Cote d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Greenland",
    "Grenada",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonalds Islands",
    "Holy See",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Reunion",
    "Saint Barthelemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin, (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten, (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Repulic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States Minor Outlying Islands",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela, Bolivarian Republic of",
    "Vietnam",
    "Virgin Islands, British",
    "Virgin Islands, U.S",
    "Wallis and Futuna"
  ];

  const data = useStaticQuery(
    graphql`
      query {
        Ourprivacybackground: file(relativePath: { eq: "our-privacy-promise-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        herobackground: file(relativePath: { eq: "hero-bg-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        herobackground: file(relativePath: { eq: "hero-bg-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        heroRightImg: file(relativePath: { eq: "map-updated.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        map_img: file(relativePath: { eq: "map-updated.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        joinPeer: file(relativePath: { eq: "peer-connect.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo_hero: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo1_img: file(relativePath: { eq: "logo1.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo2_img: file(relativePath: { eq: "logo2.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo3_img: file(relativePath: { eq: "logo3.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo4_img: file(relativePath: { eq: "logo4.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo5_img: file(relativePath: { eq: "logo5.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo6_img: file(relativePath: { eq: "logo6.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo7_img: file(relativePath: { eq: "logo7.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        logo8_img: file(relativePath: { eq: "logo8.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        why_join_us_bg: file(relativePath: { eq: "why-join-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        whyjoinusbg: file(relativePath: { eq: "Group 5.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        how_it_works_img: file(relativePath: { eq: "how-it-works-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        privacy_bg: file(relativePath: { eq: "privacy-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        time_investment_bg: file(relativePath: { eq: "background.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        timeInvestment: file(relativePath: { eq: "time-investment-imge.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        investment_img: file(relativePath: { eq: "investment-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        whatDoesItCost_img: file(relativePath: { eq: "what-does-it-cost-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        why_left_img: file(relativePath: { eq: "left-img1.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon_1: file(relativePath: { eq: "icon1.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon_2: file(relativePath: { eq: "icon2.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        peer1: file(relativePath: { eq: "share-knowledge-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        peer2: file(relativePath: { eq: "members-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        peer3: file(relativePath: { eq: "click-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        matchIcon: file(relativePath: { eq: "match-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        collaborateIcon: file(relativePath: { eq: "collaborate-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        TimeIcon: file(relativePath: { eq: "time-icon.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        sectors_background: file(relativePath: { eq: "sectors-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon_3: file(relativePath: { eq: "icon3.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon_4: file(relativePath: { eq: "icon4.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        checkIcon: file(relativePath: { eq: "check.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        testi_img1: file(relativePath: { eq: "testi-img1.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        privacyimage: file(relativePath: { eq: "privacy-promise-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        laurenImg: file(relativePath: { eq: "lauren-hardene-testi-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        glenImg: file(relativePath: { eq: "glen-santos-testi-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        arloImg: file(relativePath: { eq: "arlo-hamilton-testi-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        testi_img2: file(relativePath: { eq: "testi-img2.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        testi_img3: file(relativePath: { eq: "testi-img3.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        peer_logo_circle: file(relativePath: { eq: "peer-logo-circle.png" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        peer_logo_big: file(relativePath: { eq: "peer-logo-big.png" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        peer_logo_new: file(relativePath: { eq: "pharma-peer-new.png" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF, PNG]
            )
          }
        }
      }
    `
  )

  //declare image Data
  const hero_background = getImage(data.hero_background)
  const herobackground = getImage(data.herobackground)
  const map_img = getImage(data.map_img)
  const privacy = getImage(data.privacyimage)
  const logo1_img = getImage(data.logo1_img)
  const logo2_img = getImage(data.logo2_img)
  const logo3_img = getImage(data.logo3_img)
  const logo4_img = getImage(data.logo4_img)
  const logo5_img = getImage(data.logo5_img)
  const logo6_img = getImage(data.logo6_img)
  const logo7_img = getImage(data.logo7_img)
  const logo8_img = getImage(data.logo8_img)
  const how_it_works_img = getImage(data.how_it_works_img)
  const why_join_us_bg = getImage(data.why_join_us_bg)
  const investment_img = getImage(data.investment_img)
  const privacy_bg = getImage(data.privacy_bg)
  const time_investment_bg = getImage(data.time_investment_bg)
  const why_left_img = getImage(data.why_left_img)
  const icon_1 = getImage(data.icon_1)
  const icon_2 = getImage(data.icon_2)
  const icon_3 = getImage(data.icon_3)
  const icon_4 = getImage(data.icon_4)
  const logo_hero = getImage(data.logo_hero)
  const testi_img1 = getImage(data.testi_img1)
  const testi_img2 = getImage(data.testi_img2)
  const testi_img3 = getImage(data.testi_img3)
  const joinPeerClub = getImage(data.joinPeer)
  const peerfirst = getImage(data.peer1)
  const TimeInvestment = getImage(data.timeInvestment)
  const LaurensliderImg = getImage(data.laurenImg)
  const glenslider = getImage(data.glenImg)
  const arlolider = getImage(data.arloImg)
  const peersecond = getImage(data.peer2)
  const peerthird = getImage(data.peer3)
  const mAtchIcon = getImage(data.matchIcon)
  const cOllaborateIcon = getImage(data.collaborateIcon)
  const tImeIcon = getImage(data.TimeIcon)
  const CheckIcon = getImage(data.checkIcon)
  const PrivacyBG = getImage(data.Ourprivacybackground)
  const JoinusBG = getImage(data.whyjoinusbg)
  const whatDoesItCost = getImage(data.whatDoesItCost_img)
  const heroRightImg = getImage(data.heroRightImg)
  const peer_logo_circle = getImage(data.peer_logo_circle)
  const peer_logo_big = getImage(data.peer_logo_big)
  const peer_logo_new = getImage(data.peer_logo_new)


  //for bg image
  const hero_bg = convertToBgImage(hero_background)
  const why_join_us = convertToBgImage(why_join_us_bg)
  const privacy_section_bg = convertToBgImage(privacy_bg)
  const time_investment_section_bg = convertToBgImage(time_investment_bg)
  const herobackgroundImg = convertToBgImage(herobackground)
  const PrivayBackground = convertToBgImage(PrivacyBG)
  const JoinusBackground = convertToBgImage(JoinusBG)


  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   autoplaySpeed: 5000,
  //   speed: 1000,
  //   autoplay: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1
  // };

  const toggleHomeModal = (e) => {
    setHomeModalShow(!homeModalShow);
  }

  var settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 1000,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      }
    ]
  };

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const response = await fetch('https://peerclubv2.jenocabrera.online/api/sector');
        const data = await response.json();
        const selectedIndustries = data.data;
        setIndustries(selectedIndustries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchIndustries();
  }, []);

  return (
    <>
      <Layout  >
        <Seo
          title="Peer Club"
          description="Peer Club"
        />

        <section id="hero-section">
          <Container>
            <div className="col-lg-12">
              <Link to="/" className="logo-link">
                <GatsbyImage
                  image={peer_logo_new}
                  alt="Logo"
                  className="logo-hero"
                />
              </Link>
              <div className="row text-center">
                {/* <div className="col-lg-6 text-right left-col-btn">
                  <a href="https://app.thepeerclub.com/login?role=Peer"><button className="login-btn">LOGIN</button></a>
                </div> */}
                <div className="col-lg-12 text-left right-col-btn">
                  <Link to="/origins"><button className="origins-btn">ORIGINS</button></Link>
                </div>
              </div>
            </div>
            <div className="row" id="how-it-works">
              <div className="col-lg-5 how-works-mobile">
                <GatsbyImage
                  image={heroRightImg}
                  alt="map"
                  className="hero-img"
                  onClick={toggleHomeModal}
                />
              </div>
              <div className="col-lg-7 hero-right-col mt-2">
                {/* <h1 className="delivering-global">Delivering Global Peer Meetings</h1>
                <p className="hero-text">We are a members only meetings platform available 24/7, 365 days a year, delivering totally bespoke online curated meetings free of charge to our members.</p> */}
                <h2 className="how-it-works mt-3">How It Works </h2>
                <div className="blue-line">
                  <div className="col-lg-10">
                    <p className="they-do-text">The Peer Club is a members-only institution where peers from any country, industry, profession, sector or discipline come together to help each other solve problems and meet challenges. </p>
                  </div>
                </div>
                <div className="blue-line">
                  <div className="col-lg-10">
                    <p className="they-do-text">They do this by generously sharing expertise and knowledge in the common cause of helping each other.</p>
                  </div>
                </div>
                <div className="blue-line">
                  <div className="col-lg-10">
                    <p className="they-do-text">And Peer members can do this every day. Literally at the click of a button.</p>
                  </div>
                </div>
                {/* <p className="apply-here">Become a member apply here</p>
                <a href="https://app.thepeerclub.com/register?role=Peer" target="_blank"><button className="secondary-btn">JOIN <br /></button></a> */}
              </div>
              <div className="col-lg-5 how-works-desktop">
                <GatsbyImage
                  image={heroRightImg}
                  alt="map"
                  className="hero-img"
                />
              </div>
            </div>
          </Container>
          {/* <ContainerFluid>
            <div className="col-lg-12">
              <h3 className="text-center members-from">Members From</h3>
              <div className="row logos-container">
                <div className="col">
                  <GatsbyImage
                    image={logo1_img}
                    alt="Logo 1"
                    className="logos-img"
                  />
                </div>
                <div className="col">
                  <GatsbyImage
                    image={logo2_img}
                    alt="Logo 2"
                    className="logos-img"
                  />
                </div>
                <div className="col">
                  <GatsbyImage
                    image={logo3_img}
                    alt="Logo 3"
                    className="logos-img"
                  />
                </div>
                <div className="col">
                  <GatsbyImage
                    image={logo4_img}
                    alt="Logo 4"
                    className="logos-img"
                  />
                </div>
                <div className="col">
                  <GatsbyImage
                    image={logo5_img}
                    alt="Logo 5"
                    className="logos-img"
                  />
                </div>
                <div className="col">
                  <GatsbyImage
                    image={logo6_img}
                    alt="Logo 6"
                    className="logos-img"
                  />
                </div>
                <div className="col">
                  <GatsbyImage
                    image={logo7_img}
                    alt="Logo 7"
                    className="logos-img"
                  />
                </div>
                <div className="col">
                  <GatsbyImage
                    image={logo8_img}
                    alt="Logo 8"
                    className="logos-img"
                  />
                </div>
              </div>
            </div>
          </ContainerFluid> */}
        </section>
        <section id="our-privacy-promise">
          <Container>
            <div className="col-lg-5">
              <div className='big-peer-logo'>
                <GatsbyImage
                  image={peer_logo_new}
                  alt="Peer Logo"
                  className="peer-logo-big"
                />
              </div>
              {/* <GatsbyImage
                image={peer_logo_new}
                alt="Peer Logo"
                className="peer-logo-big"
              /> */}
            </div>
            <div className="col-lg-7 investment">
              <h2>Our Privacy Promise </h2>
              <div className="blue-line">
                <div className="col-lg-10">
                  <p className="check-text">We are a private club and won’t make our membership lists available to third parties at any time.</p>
                </div>
              </div>
              <div className="blue-line">
                <div className="col-lg-10">
                  <p className="check-text">Nor do we a provide a ‘look-up’ function where people can research who are current members of the club.</p>
                </div>
              </div>
              <div className="blue-line">
                <div className="col-lg-10">
                  <p className="check-text">We only ever share contact information between co-peers when facilitating their meeting requests.</p>
                </div>
              </div>
              <div className="blue-line">
                <div className="col-lg-10">
                  <p className="check-text">Discretion and confidentiality are our bywords.</p>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="why-join-us-bg"
          {...JoinusBackground}
          preserveStackingContext
        >
          <section id="why-join-us">
            <Container>
              <div className="col-lg-6 why-join-right-col">
                <h2>Why Join The Peer Club</h2>
                <p>The Club was established to help peers help each other.</p>
                <div className="club-row">
                  <GatsbyImage
                    image={icon_1}
                    alt="club1"
                    className="clubicon"
                  />
                  <p className="club-text">What better way to develop an opportunity, meet a challenge or solve a problem than by asking peers with the same professional needs and interests as yourself?</p>
                </div>
                <div className="club-row">
                  <GatsbyImage
                    image={icon_2}
                    alt="club3"
                    className="clubicon"
                  />
                  <p className="club-text">Our members reach out to their peer colleagues from around the globe to get the answers they need. All with the underlying comfort that all peer members sign up to club’s code of practice.</p>
                </div>
                <div className="club-row">
                  <GatsbyImage
                    image={icon_3}
                    alt="club3"
                    className="clubicon"
                  />
                  <p className="club-text">This collegial pooling of experience by peers with common interests and shared goals is the essence of the Club.</p>
                </div>
              </div>
              <div className="col-lg-6">
                <GatsbyImage
                  image={joinPeerClub}
                  alt="People"
                  className="why-img"
                />
              </div>
            </Container>
          </section>
        </BackgroundImage>
        <section id="time-investment">
          <Container>
            <div className="col-lg-6">
              <GatsbyImage
                image={TimeInvestment}
                alt="Time Investment"
                className="investment-img"
              />
            </div>
            <div className="col-lg-6 investment">
              <h2>Your Time Investment </h2>
              <div className="your-time">
                <GatsbyImage
                  image={mAtchIcon}
                  alt="icon1"
                  className="time-investment-icon"
                />
                <p className="this-ensures-text">Information we collect on members is used to profile match you with other peers with the same job level, industry or sector type and interests.</p>

              </div>
              <div className="your-time">
                <GatsbyImage
                  image={cOllaborateIcon}
                  alt="icon2"
                  className="time-investment-icon"
                />
                <p className="this-ensures-text">This ensures you get to collaborate with co-peers from within your own region and from around the world with matching experience and interests.</p>
                {/* <p className="this-ensures-text">This ensures you get to collaborate with co-peers from Ireland around the world with matching experience and interests.</p> */}
              </div>
              <div className="your-time">
                <GatsbyImage
                  image={tImeIcon}
                  alt="icon3"
                  className="time-investment-icon"
                />
                <p className="this-ensures-text">And this guarantees your time investment in the club is well rewarded with the knowledge and insights you need whenever you need them.</p>
              </div>
            </div>
          </Container>
        </section>

        <section className="pb-0" id="cost">
          <Container>
            {/* <div className="col-lg-6 text-center">
              <h2>What Does It Cost?</h2>
              <a href="https://app.thepeerclub.com/register?role=Peer" target="_blank"><button className="signup-btn">SIGN UP</button></a>
            </div> */}
            <div className="col-lg-12 text-center mb-3">
              <p>We operate as your meetings manager and co-ordinator 24/7, 365 days a year, putting together a totally bespoke and curated networking experience without charging you.</p>
              <p className="mb-0">There are no hidden charges, and you won’t be asked for credit card details or other payment methods at any stage.</p>
            </div>
          </Container>
        </section>
        <section className='pt-4' id="contact">
          <Container>
            <div className="contact-form-container">
              <div className="row">
                <div className="col-lg-4 left-contact-col">
                  {/* <h3 className="contact-form-header">Get in Touch</h3>
                  <p className="text-contact">Any question or remarks? Just write us a message!</p>
                  <div className="email-container">
                    <div>
                      <IoIosMail color={'#fff'} size={22} />
                    </div>
                    <div>
                      <a href="mailto:support@thepeerclub.com" className="contact-form-link">support@thepeerclub.com</a>
                    </div>
                  </div> */}
                  <h3 className="contact-form-header">Request for membership</h3>
                  <p className="text-contact">Please fill-in our form and a membership co-ordinator will be in touch with you within 24 hours.</p>
                  <div className="email-container">
                    <div>
                      <IoIosMail color={'#fff'} size={22} />
                    </div>
                    <div>
                      <a href="mailto:applications@thepeerclub.com" className="contact-form-link">applications@thepeerclub.com</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 contact-right-col">
                  <form action="https://usebasin.com/f/8c66e38cdf83" method="POST" id="contact-us-form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="fname"> First Name <span className="text-red">*</span></label>
                          <input type="text" name="first_name" className="form-control" id="fname" required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="lname"> Last Name <span className="text-red">*</span></label>
                          <input type="text" name="last_name" className="form-control" id="lname" required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="email">Email <span className="text-red">*</span></label>
                          <input type="email" name="email" className="form-control" id="email" required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="pnumber">Phone Number <span className="text-red">*</span></label>
                          <input type="tel" name="phone_number" className="form-control" id="pnumber" required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="country">Country <span className="text-red">*</span></label>
                          <select name='country' className="form-control" id="country" required>
                            <option value="" hidden></option>
                            {countries.map((country, index) => (
                              <option key={country + "-" + index} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label for="industry">Industry <span className="text-red">*</span></label>
                          <select name="industry" className="form-control" id="industry" required>
                            <>
                              {industries && industries.length > 0 ? (
                                <>
                                  <option value='' hidden></option>
                                  {industries
                                    .filter(({ id }) => id !== 157)
                                    .map(({ name, id }) => (
                                      <React.Fragment key={id}>
                                        <option value={name}>{name}</option>
                                      </React.Fragment>
                                    ))}
                                  {industries.some(({ id }) => id === 157) && (
                                    <React.Fragment key={157}>
                                      <option value={'Other'}>Other</option>
                                    </React.Fragment>
                                  )}
                                </>
                              ) : (
                                <>
                                  <p>No records found.</p>
                                </>
                              )}
                            </>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label for="inquiry">Inquiry <span className="text-red">*</span></label>
                          <textarea name="inquiry" className="form-control" rows="5" id="inquiry" required />
                        </div>
                      </div>
                      <div className="col-lg-12 contact-btn-container">
                        <button type="submit" className="secondary-btn">SUBMIT</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Container>
        </section>

        {/* <section id="testimonials">
          <Container>
            <div className="col-lg-12 relative">
              <h2 className="text-center">Testimonials</h2>
              <Slider className="text-center" {...settings}>
                <div className="testi-slider-container ">
                  <div className="testi-container">
                    <h3 className="mb-1">Lauren Hardene</h3>
                    <p className="testi-address mb-2">Dublin, Ireland (Europe)</p>
                    <p className="testi-content mb-0">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                  </div>
                </div>

                <div className="testi-slider-container ">
                  <div className="testi-container">
                    <h3 className="mb-1">Glen Santos</h3>
                    <p className="testi-address mb-2">Manila, Philippines (Asia)</p>
                    <p className="testi-content mb-0">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                  </div>
                </div>

                <div className="testi-slider-container ">
                  <div className="testi-container">
                    <h3 className="mb-1">Arlo Hamilton</h3>
                    <p className="testi-address mb-2">Auckland, New Zealand (Australia)</p>
                    <p className="testi-content mb-0">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                  </div>
                </div>

                <div className="testi-slider-container ">
                  <div className="testi-container">
                    <h3 className="mb-1">Lauren Hardene</h3>
                    <p className="testi-address mb-2">Dublin, Ireland (Europe)</p>
                    <p className="testi-content mb-0">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                  </div>
                </div>

                <div className="testi-slider-container ">
                  <div className="testi-container">
                    <h3 className="mb-1">Glen Santos</h3>
                    <p className="testi-address mb-2">Manila, Philippines (Asia)</p>
                    <p className="testi-content mb-0">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                  </div>
                </div>

                <div className="testi-slider-container ">
                  <div className="testi-container">
                    <h3 className="mb-1">Arlo Hamilton</h3>
                    <p className="testi-address mb-2">Auckland, New Zealand (Australia)</p>
                    <p className="testi-content mb-0">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                  </div>
                </div>

              </Slider>
            </div>
          </Container>
        </section> */}
        {homeModalShow ?
          <>
            <div className="modal show slide-bottom modal-full-width" id="home-modal" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <button type="button" className="close modal-close" data-dismiss="modal" onClick={toggleHomeModal}>&times;</button>
                  <div className="row row-1">
                    <div className="col-md-12">
                      <div className="card-body mt-0">
                        <div className='embed-container'>
                          <iframe 
                            src="https://player.vimeo.com/video/990141610?autoplay=1&loop=1&autopause=0" 
                            frameborder="0" 
                            allow="autoplay; fullscreen; picture-in-picture"></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          null
        }
      </Layout>
    </>
  )
}

export default IndexPage
